import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMail, FiPhone, FiMapPin, FiClock, FiChevronDown, FiSend } from 'react-icons/fi';
import { FaLeaf, FaSeedling, FaSpa, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import logo from '../images/logo/TD Green Logo.png';
import { FcLeft } from 'react-icons/fc';
import Footer from '../components/Fotter';
import { Loader2 } from 'lucide-react';
import { backendURL } from '../constants/constanrs';

function ContactUs() {
  const navigate = useNavigate();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [activeTab, setActiveTab] = useState('form');
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
     const response = await axios.post(`${backendURL}/api/email/send`, formData);
     console.log('Success:', response.data);
      setSubmitSuccess(true);
      setFormData({ name: "", email: "", message: "" });
      setTimeout(() => setSubmitSuccess(false), 5000);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const faqs = [
    { question: "What ingredients are used in your products?", answer: "Our products are made with a blend of high-quality ingredients, including natural oils, plant extracts, and vitamins that nourish and protect your skin and hair. We also highlight locally sourced Ethiopian ingredients where possible." },
    { question: "Are your products suitable for all skin and hair types?", answer: "Yes, our products are formulated to suit a variety of skin and hair types, including sensitive, dry, oily, and normal" },
    { question: "Can I use your products on children or sensitive skin?", answer: "Yes, many of our products are gentle enough for children and sensitive skin, but always check the label and do a patch test before use." },
    { question: "How can I contact customer service for questions or feedback?", answer: "You can reach us through email, WhatsApp, or direct message on Instagram. We’re here to help!" },
    { question: "Where can I buy your products in Ethiopia?", answer: "You can find our products in stores across Ethiopia, including major cities and towns." },
  ];
  return (
    <div className="min-h-screen bg-gradient-to-b from-sage-50 to-sage-100">
      {/* Nature-Inspired Navigation */}
      <motion.header
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className={`w-full fixed top-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/95 backdrop-blur-md shadow-sm' : 'bg-transparent backdrop-blur-0 shadow-none'
        }`}
      >
        <nav className="max-w-7xl mx-auto px-6 py-3 flex items-center justify-between">
          {/* Brand Section */}
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="flex items-center cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img
              src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743144586/TD_Green_Logo_ywtbz3.png"
              alt="TheraDerm Logo"
              className="h-14 w-14 md:h-16 md:w-16 object-contain"
            />
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center space-x-8">
     
            <motion.button
              whileHover={{ 
                scale: 1.05,
                boxShadow: "0px 5px 15px rgba(5, 150, 105, 0.3)"
              }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/')}
              className="bg-gradient-to-r from-green-600 to-emerald-500 text-white py-2.5 px-7 rounded-full shadow-md hover:shadow-lg transition-all duration-200 font-medium"
            >
              <FcLeft className="inline-block w-5 h-5 ml-1 text-white" /> Home 
            </motion.button>
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            whileTap={{ scale: 0.9 }}
            className="lg:hidden p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-200"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <svg className="h-7 w-7 text-emerald-600" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
              </svg>
            ) : (
              <svg className="h-7 w-7 text-gray-700" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" />
              </svg>
            )}
          </motion.button>
        </nav>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="lg:hidden bg-white/95 backdrop-blur-md overflow-hidden"
            >
              <div className="container mx-auto px-6 py-4 flex flex-col space-y-3">
           
                <motion.button
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    navigate('/');
                    setIsMenuOpen(false);
                  }}
                  className="bg-gradient-to-r from-green-600 to-emerald-500 text-white py-3 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 font-medium mt-2"
                >
                      <FcLeft className="inline-block w-5 h-5 ml-1 text-white" /> Home 
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>

      {/* Botanical Hero Section */}
      <section className="relative pt-32 pb-20 px-6 overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-b from-moss-900/5 to-sage-100/50"></div>
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1533038590840-1cde6e668a91?q=80&w=3000&auto=format&fit=crop')] opacity-10 bg-cover bg-center"></div>
        </div>
        
        <div className="max-w-6xl mx-auto relative z-10">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <motion.div 
              animate={{ rotate: [0, 10, -10, 0] }}
              transition={{ repeat: Infinity, duration: 5, ease: "linear" }}
              className="flex justify-center mb-4"
            >
              <FaSeedling className="text-moss-500 text-4xl" />
            </motion.div>
            <h1 className="text-5xl md:text-6xl font-serif font-light tracking-wide text-moss-900 mb-6">
              Let's Talk Natural Care
            </h1>
            <p className="text-xl text-moss-700 max-w-2xl mx-auto mb-12">
            "Have questions about our natural products? Want to partner with us? We’re here to help! Our team nurtures every inquiry like we nurture our ingredients—gently and with care."
            </p>
          </motion.div>

          <div className="flex justify-center gap-6 mb-16">
            {['form', 'faq'].map((tab) => (
              <motion.button
                key={tab}
                whileHover={{ y: -2, scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setActiveTab(tab)}
                className={`px-8 py-3 rounded-full font-medium tracking-wide transition-all ${activeTab === tab 
                  ? 'bg-moss-600 text-sage-50 shadow-md hover:bg-moss-700' 
                  : 'bg-white text-moss-700 border border-sage-300 hover:bg-sage-100 shadow-sm'}`}
              >
                {tab === 'form' ? 'Send Message' : 'FAQS'}
              </motion.button>
            ))}
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="relative pb-32 px-6">
        <div className="max-w-6xl mx-auto">
          <AnimatePresence mode="wait">
            {activeTab === 'form' ? (
              <motion.div
                key="form"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="grid md:grid-cols-2 gap-12"
              >
                {/* Organic Contact Form */}
                <div className="bg-white p-8 md:p-10 rounded-2xl shadow-lg border border-sage-200 relative overflow-hidden">
                  <div className="absolute -right-10 -top-10 text-sage-200 text-8xl opacity-20">
                    <FaSpa />
                  </div>
                  <div className="absolute -left-5 -bottom-5 text-sage-200 text-6xl opacity-20 rotate-45">
                    <FaLeaf />
                  </div>
                  <h2 className="text-3xl font-serif font-light text-moss-900 mb-8 relative z-10">
                    Leave a Message
                  </h2>
                  
                  <form ref={formRef} onSubmit={handleSubmit} className="space-y-6 relative z-10">
                    <div>
                      <label className="block text-moss-800 mb-2 font-medium">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 bg-sage-50 border border-sage-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-moss-300 focus:border-transparent transition-all placeholder-sage-400"
                        placeholder="Full name"
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-moss-800 mb-2 font-medium">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 bg-sage-50 border border-sage-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-moss-300 focus:border-transparent transition-all placeholder-sage-400"
                        placeholder="your@email.com"
                        required
                      />
                    </div>
                    
                    <div>
                      <label className="block text-moss-800 mb-2 font-medium">Your Message</label>
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 bg-sage-50 border border-sage-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-moss-300 focus:border-transparent transition-all min-h-[150px] placeholder-sage-400"
                        placeholder="How can nature serve you today?"
                        required
                      />
                    </div>
                    
                    <motion.button
                      whileHover={{ 
                        y: -2,
                        boxShadow: "0px 5px 15px rgba(87, 117, 90, 0.3)"
                      }}
                      whileTap={{ scale: 0.98 }}
                      type="submit"
                      disabled={loading}
                      className="w-full flex justify-center items-center space-x-2 bg-moss-600 hover:bg-moss-700 text-sage-50 py-4 px-6 rounded-lg shadow-md transition-all"
                    >
                      {loading ? (
                        <span className="inline-block h-5 w-5 border-2 border-hair_green border-t-transparent rounded-full animate-spin"> </span>
                      ) : (
                        <>
                          <FiSend className="w-5 h-5" />
                          <span>Send Naturally</span>
                        </>
                      )}
                    </motion.button>
                  </form>
                  
                  {submitSuccess && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="mt-6 p-4 bg-hair_oil text-white bg-moss-100 border border-moss-300 text-moss-800 rounded-lg flex items-center"
                    >
                      <svg className="w-5 h-5 mr-2 text-moss-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                      </svg>
                      We have received your message and will respond within 24 hours.
                    </motion.div>
                  )}
                </div>
                
                {/* Natural Contact Information */}
                <div className="space-y-6">
                  <div className="bg-white p-8 md:p-10 rounded-2xl shadow-lg border border-sage-200">
                    <h3 className="text-2xl font-serif font-light text-moss-900 mb-6">
                      Contact us
                    </h3>
                    
                    <div className="space-y-5">
                      <div className="flex items-start space-x-4">
                        <div className="p-3 bg-moss-100 rounded-full text-moss-600">
                          <FiMail className="w-5 h-5" />
                        </div>
                        <div>
                          <h4 className="font-medium text-moss-800">Email</h4>
                          <a href="mailto:info@theraderm.com" className="text-moss-600 hover:text-moss-900 transition-colors">
                            info@theraderm.com
                          </a>
                        </div>
                      </div>
                      
                      <div className="flex items-start space-x-4">
                        <div className="p-3 bg-moss-100 rounded-full text-moss-600">
                          <FiPhone className="w-5 h-5" />
                        </div>
                        <div>
                          <h4 className="font-medium text-moss-800">Phone</h4>
                          <a href="tel:+251974155978" className="text-moss-600 hover:text-moss-900 transition-colors">
                            +251 97 415 5978
                          </a>
                        </div>
                      </div>
                      
                      
                      <div className="flex items-start space-x-4">
                        <div className="p-3 bg-moss-100 rounded-full text-moss-600">
                          <FiClock className="w-5 h-5" />
                        </div>
                        <div>
                          <h4 className="font-medium text-moss-800">Business Hours</h4>
                          <p className="text-moss-600">
                            Monday - Friday: 9:00 AM - 5:00 PM<br />
                            Saturday: 08:00 AM - 3:00 PM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="bg-white p-8 md:p-10 rounded-2xl shadow-lg border border-sage-200">
                    <h3 className="text-2xl font-serif font-light text-moss-900 mb-6">
                      Our Location
                    </h3>
                    
                    <div className="space-y-5">
                      <div className="flex items-start space-x-4">
                        <div className="p-3 bg-moss-100 rounded-full text-moss-600">
                          <FiMapPin className="w-5 h-5" />
                        </div>
                        <div>
                          <h4 className="font-medium text-moss-800">Headquarter</h4>
                          <p className="text-moss-600">
                            Meskel Flower, Addis Ababa, Ethiopia
                          </p>
                        </div>
                      </div>
                      
                      <div className="relative h-48 mt-4 rounded-lg overflow-hidden border border-sage-300">
                      <div className="absolute inset-0 bg-[url('https://res.cloudinary.com/dj2apocoe/image/upload/v1743081494/tamas-tuzes-katai-rEn-AdBr3Ig-unsplash_x8qcdl.jpg')] bg-cover bg-[center_top_60%]"></div>

                  <div className="absolute inset-0 bg-moss-900/30"></div>
                  <div className="relative z-10 h-full flex items-center justify-center">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-white/90 text-moss-800 px-4 py-2 rounded-full shadow-md hover:bg-white transition-all flex items-center"
                      onClick={() => window.open('https://www.google.com/maps/place/Aster+Plaza/@8.9883558,38.7628374,17z/data=!3m1!4b1!4m6!3m5!1s0x164b85c0919415af:0x32c558989f08c3b6!8m2!3d8.9883558!4d38.7654123!16s%2Fg%2F11h6v4pbz4?hl=am&entry=ttu&g_ep=EgoyMDI1MDMyNC4wIKXMDSoASAFQAw%3D%3D', '_blank')}
                    >
                      <FiMapPin className="mr-2" />
                      View On Map
                    </motion.button>
                  </div>
                </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="faq"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="max-w-3xl mx-auto"
              >
                <h2 className="text-3xl font-serif font-light text-moss-900 mb-12 text-center">
                  care Knowledge
                </h2>
                
                <div className="space-y-4">
                  {faqs.map((faq, index) => (
                    <motion.div 
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="border border-sage-300 rounded-xl overflow-hidden bg-white shadow-sm hover:shadow-md transition-shadow"
                    >
                      <button
                        onClick={() => setActiveFAQ(activeFAQ === index ? null : index)}
                        className="w-full flex justify-between items-center p-6 text-left hover:bg-sage-50 transition-colors"
                      >
                        <h3 className="text-lg font-medium text-moss-800 flex items-center">
                          <FaLeaf className="text-moss-500 mr-3 text-sm" />
                          {faq.question}
                        </h3>
                        <FiChevronDown className={`w-5 h-5 text-moss-500 transition-transform ${activeFAQ === index ? 'rotate-180' : ''}`} />
                      </button>
                      
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: activeFAQ === index ? 'auto' : 0 }}
                        className="overflow-hidden"
                      >
                        <div className="px-6 pb-6 text-moss-600 pl-12">
                          {faq.answer}
                        </div>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </section>

      {/* Floating Leaves Decorations */}
      <div className="fixed top-20 left-10 text-sage-300 text-4xl opacity-20 animate-float pointer-events-none z-0">
        <FaLeaf />
      </div>
      <div className="fixed bottom-40 right-16 text-moss-300 text-3xl opacity-20 animate-float-delay pointer-events-none z-0">
        <FaLeaf className="rotate-45" />
      </div>
      <div className="fixed top-1/3 right-24 text-sage-200 text-5xl opacity-15 animate-float-slow pointer-events-none z-0">
        <FaLeaf className="rotate-12" />
      </div>
      <div className="fixed bottom-1/4 left-20 text-moss-200 text-4xl opacity-15 animate-float-delay-slow pointer-events-none z-0">
        <FaLeaf className="-rotate-12" />
      </div>

      {/* Footer */}
     <Footer/>
    </div>
  );
}

export default ContactUs;