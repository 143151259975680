import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import Carousel from '../components/carousel';
import AboutTheraderm from '../components/about_company';
import Catalogues from '../components/Catalogues';
import Hair_care from '../components/Shampoo';
import BodycareCatalog from '../components/BodycateCatalog';
import Conditioner from '../components/Conditioner';
import Hairoil from '../components/Hairoil';
import Glycerin from '../components/Glycerin';
import FragranceGlycerin from '../components/FragranceGlycerin';
import BodyLotion from '../components/Body_Lotion';
import Bodywash from '../components/Body_wash';
import Handwash from '../components/Hand_Wash';
import Footer from '../components/Fotter';

import logo from '../images/logo/TD Green Logo.png';
import vid from '../images/Vids/Waterfall Clip(720P_HD).mp4';
import Hairpattern from '../images/patterns/TDwebbackground-01.png';
import Bodypattern from '../images/patterns/TDwebbackground-02.png';

function Home() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Updated slider URLs
  const slider1 = 'https://res.cloudinary.com/dj2apocoe/image/upload/v1743088106/TD_Slider_for_Web_1920px_x_1080px-01_qjrzfs.png';
  const slider2 = 'https://res.cloudinary.com/dj2apocoe/image/upload/v1743088105/TD_Slider_for_Web_1920px_x_1080px-02_fmssey.png';
  const slider3 = 'https://res.cloudinary.com/dj2apocoe/image/upload/v1743088115/TD_Slider_for_Web_1920px_x_1080px-03_inpi6a.png';
  const slider4 = 'https://res.cloudinary.com/dj2apocoe/image/upload/v1743088115/TD_Slider_for_Web_1920px_x_1080px-04_kubvwp.png';

  const slides = [slider1, slider2, slider3, slider4];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="w-full overflow-x-hidden">
     {/* Navigation */}
     <motion.header
  initial={{ y: -100, opacity: 0 }}
  animate={{ y: 0, opacity: 1 }}
  transition={{ duration: 0.5, ease: "easeOut" }}
  className={`w-full fixed top-0 z-50 transition-all duration-300 ${
    isScrolled ? 'bg-white/95 backdrop-blur-md shadow-sm' : 'bg-transparent backdrop-blur-0 shadow-none'
  }`}
>
  <nav className="max-w-7xl mx-auto px-6 py-3 flex items-center justify-between">
    {/* Brand Section */}
    <motion.div 
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="flex items-center cursor-pointer"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <img
        src='https://res.cloudinary.com/dj2apocoe/image/upload/v1743144586/TD_Green_Logo_ywtbz3.png'
        alt="TheraDerm Logo"
        className="h-14 w-14 md:h-16 md:w-16 object-contain"
      />
    </motion.div>

    {/* Desktop Navigation */}
    <div className="hidden lg:flex items-center space-x-8">
      {['Home', 'About', 'Products'].map((item) => (
        <motion.div 
          key={item}
          whileHover={{ y: -2 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <a
            href={`#${item.toLowerCase()}`}
            className="relative px-3 py-2 text-gray-700 hover:text-emerald-600 font-medium transition duration-300 group"
          >
            {item}
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-emerald-500 transition-all duration-300 group-hover:w-full"></span>
          </a>
        </motion.div>
      ))}
      <motion.button
        whileHover={{ 
          scale: 1.05,
          boxShadow: "0px 5px 15px rgba(5, 150, 105, 0.3)"
        }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate('/contactus')}
        className="bg-gradient-to-r from-green-600 to-emerald-500 text-white py-2.5 px-7 rounded-full shadow-md hover:shadow-lg transition-all duration-200 font-medium"
      >
        Contact Us
      </motion.button>
    </div>

    {/* Mobile Menu Button */}
    <motion.button
      whileTap={{ scale: 0.9 }}
      className="lg:hidden p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-200"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      {isMenuOpen ? (
        <svg className="h-7 w-7 text-emerald-600" viewBox="0 0 24 24">
          <path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
        </svg>
      ) : (
        <svg className="h-7 w-7 text-gray-700" viewBox="0 0 24 24">
          <path fill="currentColor" d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" />
        </svg>
      )}
    </motion.button>
  </nav>

  {/* Mobile Menu */}
  <AnimatePresence>
    {isMenuOpen && (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="lg:hidden bg-white/95 backdrop-blur-md overflow-hidden"
      >
        <div className="container mx-auto px-6 py-4 flex flex-col space-y-3">
          {['Home', 'About', 'Products'].map((item) => (
            <motion.a
              key={item}
              whileTap={{ scale: 0.98 }}
              href={`#${item.toLowerCase()}`}
              className="text-gray-700 hover:text-emerald-600 font-medium transition duration-300 py-2.5 px-4 rounded-lg hover:bg-emerald-50"
              onClick={() => setIsMenuOpen(false)}
            >
              {item}
            </motion.a>
          ))}
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              navigate('/contactus');
              setIsMenuOpen(false);
            }}
            className="bg-gradient-to-r from-green-600 to-emerald-500 text-white py-3 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 font-medium mt-2"
          >
            Contact Us
          </motion.button>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
</motion.header>

      {/* Main Content */}
      <main className="pt-20">
        {/* Hero Carousel */}
        <section id="home" className="relative">
          <Carousel slides={slides} />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="absolute bottom-10 left-0 right-0 flex justify-center"
          >
            <a
              href="#theraderm"
              className="animate-bounce bg-white/80 text-gray-800 p-2 rounded-full shadow-lg"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 14l-7 7m0 0l-7-7m7 7V3"
                />
              </svg>
            </a>
          </motion.div>
        </section>

        {/* About Section */}
        <section id="theraderm" className="py-16">
          <AboutTheraderm />
        </section>

        {/* Video Background Section */}
        <section id='product-catalogue' className="relative w-full h-screen flex justify-center items-center overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full">
            <video
              className="w-full h-full object-cover"
              src={vid}
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
          <div className="absolute inset-0 bg-black/40"></div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="relative z-10 container mx-auto px-4"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-black z-50 text-center mb-12">
              Explore Our Collections
            </h2>
            <div className="flex flex-col md:flex-row justify-center items-center gap-8">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a href="#hair_care">
                  <Catalogues />
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a href="#body_care">
                  <BodycareCatalog />
                </a>

              </motion.div>
            </div>
          </motion.div>
        </section>

        {/* Products Sections */}
        <section id="products" className="py-16">
          {/* Hair Care Section */}
          <div
            id="hair_care"
            className="bg-cover bg-center py-16"
            style={{ backgroundImage: `url(${Hairpattern})` }}
          >
            <div className="container mx-auto px-4">
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="text-4xl font-bold text-white text-center mb-16"
              >
                <h1 className="text-4xl uppercase md:text-5xl font-bold mb-2 font-serif">
                Hair Care Collections
                </h1>
              </motion.h2>
              <Hair_care />
              <Conditioner />
              <Hairoil />
            </div>
          </div>

          {/* Body Care Section */}
          <div
            id="body_care"
            className="bg-cover bg-center py-16"
            style={{ backgroundImage: `url(${Bodypattern})` }}
          >
            <div className="container mx-auto px-4">
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="text-5xl font-bold text-white text-center mb-16"
              >
                <h1 className="text-4xl uppercase md:text-5xl font-bold mb-2 font-serif">
                Body Care Collection
                </h1>
              </motion.h2>
              <Glycerin />
              <FragranceGlycerin />
              <BodyLotion />
              <Bodywash />
              <Handwash />
            </div>
          </div>
        </section>
      </main>
<Footer />
    </div>
  );
}

export default Home;