import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import slider1 from '../src/images/slider/TD Slider for Web 1920px x 1080px-04.png';
import slider2 from '../src/images/slider/TD Slider for Web 1920px x 1080px-03.png';
import slider3 from '../src/images/slider/TD Slider for Web 1920px x 1080px-02.png';
import slider4 from '../src/images/slider/TD Slider for Web 1920px x 1080px-01.png';
import trueBeauty from './images/logo/unlock.png'; 

import Home from './pages/Home';
import ContactUs from './pages/Contactus';
import "@fontsource/playfair-display";
import "@fontsource/playfair-display/400-italic.css";
import { useEffect, useState } from 'react';
import bg from '../src/images/patterns/welcome.png';
import './App.css'; // Additional custom styles

function App() {
  const slides = [slider4, slider3, slider2, slider1];
  const [flash, setFlashOpen] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFlashOpen(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <AnimatePresence mode='wait'>
      {flash ? (
        <motion.div
          key="splash"
          className="flex flex-col items-center justify-center h-screen bg-cover bg-center"
          style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bg})` }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Animated Logo with subtle pulse */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ 
              opacity: 1, 
              scale: 1,
              transition: { 
                duration: 1,
                ease: "easeOut"
              }
            }}
            whileHover={{ scale: 1.05 }}
          >
            <img 
              src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743144676/unlock_osejc5.png"
              alt="Logo of True Beauty" 
              className="md:h-96 md:w-96 h-60 w-60 filter drop-shadow-lg" 
            />
          </motion.div>

          {/* Elegant Text Animation */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ 
              opacity: 1, 
              y: 0,
              transition: { 
                duration: 1.2,
                delay: 0.8,
                ease: [0.2, 0.8, 0.4, 1]
              }
            }}
          >
            <h1 className="text-white md:text-6xl text-4xl text-center font-playfair italic tracking-wide mt-8">
              <span className="text-gradient bg-clip-text text-transparent bg-gradient-to-r from-rose-300 to-amber-200">
                "Unlock Your Inner Beauty"
              </span>
            </h1>
            
            {/* Subtle loading indicator */}
            <motion.div 
              className="mt-12 mx-auto h-1 bg-white/30 w-64 rounded-full overflow-hidden"
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 2.8, ease: "linear" }}
            >
              <motion.div 
                className="h-full bg-gradient-to-r from-rose-400 to-amber-300"
                initial={{ x: "-100%" }}
                animate={{ x: "0%" }}
                transition={{ duration: 2.8, ease: "linear" }}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      ) : (
        <motion.div
          key="app"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Router>
            <div className="w-full min-h-screen ">
              <Routes>
                <Route path="/" element={<Home slides={slides} />} />
                <Route path="/contactus" element={<ContactUs />} />
              </Routes>
           
            </div>
          </Router>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default App;