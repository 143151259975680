import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { GiMailbox } from 'react-icons/gi';
import { TfiEmail } from 'react-icons/tfi';
import { FaTiktok, FaXTwitter } from 'react-icons/fa6';
import logo from '../images/logo/fotterlogo.png';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { PiPhone } from 'react-icons/pi';

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [responseMessage, setResponseMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const scrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const SubscribeEmail = async (email) => {
    try {
      setLoading(true);
      setResponseMessage('');
      
      const response = await axios.post('https://theradermbackend.onrender.com/api/subscribe', { email });
      const successMessage = response?.data?.message;

      if (response.status === 201) {
        setResponseMessage(successMessage || 'Subscribed successfully!');
        setShowSuccess(true);
        setEmail('');
      } 
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Failed to subscribe. Please try again later.';
      setResponseMessage(errorMessage);
      setShowSuccess(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowSuccess(false);
        setResponseMessage('');
      }, 5000);
    }
  };

  return (
    <>
      {/* Success Notification */}
      {showSuccess && (
        <div className="fixed top-10 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-6 py-3 rounded-lg shadow-lg z-50 flex items-center animate-fade-in">
          <svg className="w-5 h-5 mr-2 text-green-600" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
          <span>{responseMessage}</span>
        </div>
      )}

      {/* Main Footer */}
      <footer className="bg-gradient-to-b from-gray-50 to-gray-100 text-gray-700 pt-16 pb-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* Quick Links Section */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-gray-800 uppercase tracking-wider">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <button 
                  onClick={() => scrollToSection("home")} 
                  className="text-gray-600 hover:text-gray-900 transition-all duration-300 hover:pl-2 flex items-center group"
                >
                  <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 group-hover:bg-gray-900 transition-all"></span>
                  Home
                </button>
              </li>
              <li>
                <button 
                  onClick={() => scrollToSection("theraderm")}  
                  className="text-gray-600 hover:text-gray-900 transition-all duration-300 hover:pl-2 flex items-center group"
                >
                  <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 group-hover:bg-gray-900 transition-all"></span>
                  About Us
                </button>
              </li>
              <li>
                <button 
                  onClick={() => scrollToSection("products")}  
                  className="text-gray-600 hover:text-gray-900 transition-all duration-300 hover:pl-2 flex items-center group"
                >
                  <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 group-hover:bg-gray-900 transition-all"></span>
                  Products
                </button>
              </li>
              <li>
                <button 
                  onClick={() => navigate('/contactus')}
                  className="text-gray-600 hover:text-gray-900 transition-all duration-300 hover:pl-2 flex items-center group"
                >
                  <span className="w-1 h-1 bg-gray-400 rounded-full mr-2 group-hover:bg-gray-900 transition-all"></span>
                  Contact
                </button>
              </li>
            </ul>
          </div>

          {/* Contact Info Section */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-gray-800 uppercase tracking-wider">Contact Us</h3>
            <div className="flex items-start space-x-4">
              <div className="border-r border-gray-200 pr-4">
                <img
                  src={logo}
                  alt="Theraderm Logo"
                  className="w-28 transition-transform hover:scale-105"
                />
              </div>
              <div className="space-y-2">
                <p className="text-gray-700 font-medium">
                  <span className="block text-gray-900">P.O. Box 14572</span>
                  <span className="text-gray-600">Addis Ababa, Ethiopia</span>
                </p>
              </div>
            </div>

            <div className="space-y-4 mt-4">
              <div className="flex items-center space-x-3 group">
                <div className="p-2 bg-gray-200 rounded-full group-hover:bg-red-100 transition-all">
                  <TfiEmail className="text-gray-600 group-hover:text-red-500 transition-all" />
                </div>
                <a
                  href="mailto:info@theradermethiopia.com"
                  className="text-gray-700 hover:text-gray-900 transition-all"
                >
                  info@theradermethiopia.com
                </a>
              </div>

              <div className="flex items-center space-x-3 group">
                <div className="p-2 bg-gray-200 rounded-full group-hover:bg-blue-100 transition-all">
                  <PiPhone className="text-gray-600 group-hover:text-blue-500 transition-all" />
                </div>
                <a
                  href="tel:+251974155978"
                  className="text-gray-700 hover:text-gray-900 transition-all"
                >
                  +251 97 415 5978
                </a>
              </div>
            </div>
          </div>

          {/* Social Media Section */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-gray-800 uppercase tracking-wider">Follow Us</h3>
            <p className="text-gray-600">Connect with us on social media</p>
            <div className="flex flex-wrap gap-4">
              {[
                { 
                  icon: <FaTiktok className="text-2xl" />, 
                  href: "https://www.tiktok.com/@theraderm.ethio?_t=8q2IwhFl3Yc&_r=1", 
                  color: "hover:bg-gray-800 hover:text-white",
                  label: "TikTok"
                },
                { 
                  icon: <FaFacebook className="text-2xl" />, 
                  href: "https://web.facebook.com/profile.php?id=61565927445697", 
                  color: "hover:bg-blue-600 hover:text-white",
                  label: "Facebook"
                },
                { 
                  icon: <FaYoutube className="text-2xl" />, 
                  href: "https://www.youtube.com/@theradermethiopia", 
                  color: "hover:bg-red-600 hover:text-white",
                  label: "YouTube"
                },
                { 
                  icon: <FaInstagram className="text-2xl" />, 
                  href: "https://www.instagram.com/theraderm.ethio?igsh=MWtyOWJsZjRscGQ5ag==", 
                  color: "hover:bg-pink-500 hover:text-white",
                  label: "Instagram"
                },
                { 
                  icon: <FaXTwitter className="text-2xl" />, 
                  href: "https://x.com/theradermethio?s=21", 
                  color: "hover:bg-black hover:text-white",
                  label: "X (Twitter)"
                }
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`p-3 bg-white rounded-full shadow-sm text-gray-700 transition-all duration-300 ${social.color}`}
                  aria-label={social.label}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Newsletter Section */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-gray-800 uppercase tracking-wider">Newsletter</h3>
            <p className="text-gray-600">Stay updated with our latest news and offers</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SubscribeEmail(email);
              }}
              className="space-y-4"
            >
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-3 bg-white text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                  required
                />
                <MdEmail className="absolute right-3 top-3.5 text-gray-400" />
              </div>
              <button
                type="submit"
                className={`w-full px-6 py-3 bg-gradient-to-r from-hair_green to-shampoo text-white rounded-lg shadow-md transition-all ${
                  loading ? 'opacity-80 cursor-not-allowed' : 'hover:shadow-lg hover:from-shampoo hover:to-hair_green'
                } flex items-center justify-center`}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <GiMailbox className="mr-2" />
                    Subscribe
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-16 pt-8 border-t border-gray-200 text-center">
          <p className="text-sm text-gray-500">
            &copy; {new Date().getFullYear()} YYDTS. All rights reserved.
          </p>
          <p className="text-xs text-gray-400 mt-2">
           " Unlock Your Inner Beauty "
          </p>
        </div>
      </footer>

      {/* Add these styles for animations */}
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fade-in {
          animation: fadeIn 0.3s ease-out forwards;
        }
      `}</style>
    </>
  );
}

export default Footer;