import React from 'react';
import { motion } from 'framer-motion';
import avo_shampoo from '../images/products/hair-care/Avocado-Shampoo.png';
import coconut_shampoo from '../images/products/hair-care/Coconut-Shampoo.png';
import avo_shabg from '../images/prod_ngs/TheraDermBackgroundPictures-01.png';
import coco_shabg from '../images/prod_ngs/TheraDermBackgroundPictures-02.png';

function Shampoo() {
  return (
    <div className="">
      {/* Header Section */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="py-12 text-center"
      >
        <h1 className="text-2xl md:text-4xl font-bold text-white mb-2 font-serif">
          SHAMPOO 
        </h1>
        <div className="w-48 h-1 bg-amber-500 mx-auto mb-4"></div>
        <p className="text-amber-600 text-lg md:text-xl uppercase tracking-wider">
          In avocado & coconut variants
        </p>
      </motion.div>

      {/* Avocado Shampoo Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        className="relative overflow-hidden my-8 mx-auto max-w-6xl rounded-xl shadow-lg border-l-4 border-amber-500"
        style={{ 
          backgroundImage: `url(${avo_shabg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-black/30"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 relative z-10">
          {/* Image Gallery */}
          <div className="flex flex-col items-center justify-center p-6 lg:p-8">
            <div className="flex items-center justify-center space-x-4 md:space-x-6">
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-48 md:h-64 w-auto transition-all duration-300"
              >
                <img
                  src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743143943/Avocado-Shampoo_runsmz.png"
                  alt="Avocado Shampoo"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-64 md:h-80 w-auto transition-all duration-300"
              >
                <img
                  src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743143943/Avocado-Shampoo_runsmz.png"
                  alt="Avocado Shampoo"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
            </div>
            <div className="mt-4">
              <p className="text-white text-sm bg-black/30 px-3 py-1 rounded-full">
                Available in 300ml & 500ml
              </p>
            </div>
          </div>

          {/* Product Info */}
          <div className="flex flex-col justify-center p-6 lg:p-8 text-white">
            <motion.h2 
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-2xl md:text-3xl font-bold mb-4 text-center lg:text-left"
            >
              Avocado Shampoo
            </motion.h2>
            
            <motion.p
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="text-white/90 mb-6 text-center lg:text-left text-sm md:text-base"
            >
              <span className="text-amber-300 font-semibold">THERA DERM's Avocado Shampoo</span>, enriched with the goodness of Aloe Vera, gently cleanses and moisturizes hair. It restores strength and shine to weak and damaged hair.
            </motion.p>

            <motion.div
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="text-center lg:text-left italic text-lg font-serif"
            >
              "For <span className="text-amber-300">strong</span> & <span className="text-amber-300">shiny</span> hair"
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Coconut Shampoo Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        className="relative overflow-hidden my-8 mx-auto max-w-6xl rounded-xl shadow-lg border-l-4 border-amber-500"
        style={{ 
          backgroundImage: `url(${coco_shabg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-black/30"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 relative z-10">
          {/* Product Info */}
          <div className="flex flex-col justify-center p-6 lg:p-8 text-white order-2 lg:order-1">
            <motion.h2 
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-2xl md:text-3xl font-bold mb-4 text-center lg:text-right"
            >
              Coconut Shampoo
            </motion.h2>
            
            <motion.p
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="text-white/90 mb-6 text-center lg:text-right text-sm md:text-base"
            >
              <span className="text-amber-300 font-semibold">THERA DERM's Coconut Shampoo</span>, enriched with Coconut Oil, gently cleanses and moisturizes hair. It restores strength and shine to weak and damaged hair.
            </motion.p>

            <motion.div
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="text-center lg:text-right italic text-lg font-serif"
            >
              "For <span className="text-amber-300">soft</span> & <span className="text-amber-300">silky</span> hair"
            </motion.div>
          </div>

          {/* Image Gallery */}
          <div className="flex flex-col items-center justify-center p-6 lg:p-8 order-1 lg:order-2">
            <div className="flex items-center justify-center space-x-4 md:space-x-6">
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-48 md:h-64 w-auto transition-all duration-300"
              >
                <img
                  src = "https://res.cloudinary.com/dj2apocoe/image/upload/v1743144161/Coconut-Shampoo_zyi7zq.png"
                  alt="Coconut Shampoo"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-64 md:h-80 w-auto transition-all duration-300"
              >
                <img
                  src = "https://res.cloudinary.com/dj2apocoe/image/upload/v1743144161/Coconut-Shampoo_zyi7zq.png"
                  alt="Coconut Shampoo"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
            </div>
            <div className="mt-4">
              <p className="text-white text-sm bg-black/30 px-3 py-1 rounded-full">
                Available in 300ml & 500ml
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Shampoo;