import React from 'react';
import logo from '../images/logo/TD Green Logo.png';
import { motion } from 'framer-motion';
import { GiLeafSwirl } from 'react-icons/gi';

function AboutTheradem() {
  return (
    <div className="flex justify-center py-12 px-4 sm:px-8 bg-gradient-to-b from-gray-50 to-white">
      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="flex flex-col items-center bg-white p-8 rounded-2xl shadow-lg w-full max-w-5xl overflow-hidden"
      >
        {/* Decorative elements */}
        <div className="absolute top-0 left-0 w-full h-2 bg-emerald-500"></div>
        <GiLeafSwirl className="absolute top-4 right-4 text-emerald-100 text-4xl opacity-60" />
        
        {/* Logo Section with enhanced animation */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ type: 'spring', stiffness: 100, delay: 0.2 }}
          className="mt-2 mb-8"
        >
          <img
            src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743144586/TD_Green_Logo_ywtbz3.png"
            alt="TheraDerm Logo"
            className="w-40 h-40 object-contain mx-auto hover:scale-105 transition-transform duration-500"
          />
          <motion.h2 
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="text-center text-2xl font-serif text-emerald-800 mt-4"
          >
            The Essence of Natural Care
          </motion.h2>
        </motion.div>

        {/* Description Section with improved typography */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="absolute -left-4 top-0 h-full w-1 bg-emerald-200 rounded-full"></div>
            <p className="text-gray-700 text-lg leading-relaxed text-justify p-4">
              Thera Derm comes from the Greek words for care ("Thera") and skin ("Derma"), 
              reflecting our promise to nurture and protect your natural beauty.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative"
          >
            <div className="absolute -left-4 top-0 h-full w-1 bg-emerald-200 rounded-full"></div>
            <p className="text-gray-700 text-lg leading-relaxed text-justify p-4">
              Locally made and thoughtfully formulated for Ethiopian skin and hair, 
              our range blends trusted ingredients like white honey, almond oil, 
              pure glycerin, avocado, and coconut oil — offering deep nourishment, 
              hydration, and everyday glow.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="lg:col-span-2 relative bg-emerald-50 rounded-xl p-6"
          >
            <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-emerald-400 to-teal-300"></div>
            <p className="text-gray-800 text-lg leading-relaxed text-center italic font-medium">
              "Combining quality and affordability, Thera Derm is your go-to for effective, 
              feel-good self-care. Unlock your true beauty — with Thera Derm."
            </p>
          </motion.div>
        </div>

        {/* Signature/Call to Action */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6 }}
          className="mt-8 text-center"
        >
          <button
            onClick={() => document.getElementById("product-catalogue")?.scrollIntoView({ behavior: "smooth" })}
            className="px-8 py-3 bg-gradient-to-r from-emerald-500 to-teal-600 text-white rounded-full font-medium hover:shadow-lg transition-all duration-300 hover:scale-105"
          >
            Discover Our Products
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default AboutTheradem;