import React from 'react';
import { motion } from 'framer-motion';
import body_wash from '../images/products/body-care/Body-Wash.png';
import bodWas from '../images/prod_ngs/TheraDermBackgroundPictures-07.png';

function Bodywash() {
  return (
    <div className="">
      {/* Header Section */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="py-12 text-center"
      >
        <h1 className="text-2xl md:text-4xl font-bold text-body_washbg mb-2 font-serif">
          BODY WASH
        </h1>
        <div className="w-48 h-1 bg-amber-500 mx-auto mb-4"></div>
        <p className="text-white text-lg md:text-xl uppercase tracking-wider">
          With Honey & Glycerin
        </p>
      </motion.div>

      {/* Product Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8 }}
        className="relative overflow-hidden my-8 mx-auto max-w-6xl rounded-xl shadow-2xl border-t-4 border-amber-500"
        style={{ 
          backgroundImage: `url(${bodWas})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-black/30"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 relative z-10">
          {/* Product Info */}
          <div className="flex flex-col justify-center p-8 lg:p-12 text-white">
            <motion.h2 
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-3xl md:text-4xl font-bold mb-6 text-center lg:text-left"
            >
              Moisturizing Body Wash
            </motion.h2>
            
            <motion.p
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="text-white/90 mb-8 text-center lg:text-left"
            >
              <span className="text-amber-300 font-semibold">THERA DERM's Body Wash</span> contains active ingredients that create an intensive moisturizing and skin-softening sensation. It calms and soothes skin irritations and itchiness, leaving the skin delicately scented.
            </motion.p>

            <motion.div
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="text-center lg:text-left italic text-xl mb-8 font-serif"
            >
              "For <span className="text-amber-300">refreshed</span> & <span className="text-amber-300">hydrated</span> skin"
            </motion.div>

      
          </div>

          {/* Image Gallery */}
          <div className="flex flex-col items-center justify-center p-8 lg:p-12">
            <div className="flex items-center justify-center space-x-4 md:space-x-6 mb-6">
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-48 md:h-64 w-auto transition-all duration-300"
              >
                <img
                  src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743144490/Body-Wash_wczsmr.png"
                  alt="Body Wash"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="h-64 md:h-80 w-auto transition-all duration-300"
              >
                <img
                  src="https://res.cloudinary.com/dj2apocoe/image/upload/v1743144490/Body-Wash_wczsmr.png"
                  alt="Body Wash"
                  className="h-full w-auto object-contain drop-shadow-lg"
                />
              </motion.div>
            </div>
            <div className="mt-4">
              <p className="text-white text-sm bg-black/30 px-3 py-1 rounded-full">
                Available in <span className="text-amber-300 font-medium">300ml</span> &{' '}
                <span className="text-amber-300 font-medium">500ml</span>
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Bodywash;